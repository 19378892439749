import React from "react";
import styled from "styled-components";
import bg from "../assets/Images/aboutus.webp";

import { useTranslation } from "react-i18next";
import OurVision from "../components/OurVision";
import TextSection from "../components/TextSection";
import galleryImg from "../assets/Images/gallery.jpg";
import certificatesImg from "../assets/Images/certificates.jpg";
const Contaniner = styled.div`
  width: 100%;
  position: relative;
  height: 40vh;
  img {
    width: 100%;
    height: auto;
  }
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
`;
const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(191, 25, 33, 0.6);
`;

const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div className="overflow-hidden">
      <Contaniner>
        <DarkOverlay></DarkOverlay>
      </Contaniner>
      <TextSection />
      <OurVision />
      <div className="flex flex-col lg:flex-row items-end justify-around gap-20 p-6 m-4">
        <div
          onClick={() => (window.location.href = "/gallery")}
          className="cursor-pointer text-center"
        >
          <div className="flex flex-col items-end">
            <img
              src={galleryImg}
              alt="Project Gallery"
              className="w-[300px] h-[300px] object-cover shadow-md"
            />
          </div>
          <div className="max-w-[300px] bg-[#393E46] text-white py-2 px-4 text-2xl">
            {t("about-us.galleryDesc")}
          </div>
        </div>

        <div
          onClick={() => (window.location.href = "/certificates")}
          className="cursor-pointer text-center"
        >
          <div className="flex flex-col items-end">
            <img
              src={certificatesImg}
              alt="Certificates and Official Documents"
              className="w-[300px] object-cover h-[300px] shadow-md"
            />
          </div>
          <div className="max-w-[300px] bg-[#393E46] text-white py-2 px-4 text-2xl">
            {t("about-us.certificatesDesc")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
