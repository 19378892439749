import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";
import { IoClose } from "react-icons/io5";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Item = styled(motion.li)`
  text-transform: uppercase;
  color: #bf1922;
  &:hover {
    color: #22252a;
  }
  flex-direction: column;
  padding: 0.5rem 0;
`;
const MobileDrawer = ({ isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const tn = (key) => t(`navbar.${key}`);
  const { scroll } = useLocomotiveScroll();
  const handleScroll = (id) => {
    let elem = document.querySelector(id);
    scroll.scrollTo(elem, {
      offset: "-100",
      duration: "2000",
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };
  return (
    <>
      <div
        className={`fixed bottom-0 left-0 right-0 bg-white drop-shadow-2xl transform transition-transform rounded-lg  ${
          isOpen ? "translate-y-full" : "translate-y-0 "
        }`}
      >
        <ul
          className={`${
            isOpen ? "flex flex-col" : "hidden"
          } justify-center items-center h-full gap-4`}
        >
          <button onClick={() => setIsOpen(false)} className="mt-4">
            <IoClose size={32} />
          </button>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#home")}
          >
            <Link to="/">{tn("home")}</Link>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll(".about")}
          >
            <Link to="/">{tn("about")}</Link>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#shop")}
          >
            <Link to="/">{tn("partners")}</Link>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#sectors")}
          >
            <Link to="/">{tn("sectors")}</Link>
          </Item>

          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#projects")}
          >
            {" "}
            <Link to="/">{tn("projects")}</Link>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#contact")}
          >
            {" "}
            <Link to="/">{tn("contact")}</Link>
          </Item>
        </ul>
      </div>
    </>
  );
};

export default MobileDrawer;
