import "locomotive-scroll/dist/locomotive-scroll.css";

import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { LocomotiveScrollProvider } from "react-locomotive-scroll";
import { ThemeProvider } from "styled-components";

import { Route, Routes } from "react-router-dom";
import GoToTopBtn from "./components/GoToTopBtn";
import Loader from "./components/Loader";
import Navbar from "./components/Navbar";
import ProjectsContainer from "./components/ProjectsContainer.jsx";
import ScrollTriggerProxy from "./components/ScrollTriggerProxy";
import ShopContainer from "./components/ShopContainer.jsx";
import About from "./sections/About";
import AboutPage from "./sections/AboutPage";
import Contactus from "./sections/Contactus";
import Footer from "./sections/Footer";
import Gallery from "./sections/Gallery.jsx";
import Home from "./sections/Home";
import ProjectsDetails from "./sections/ProjectsDetails";
import Sectors from "./sections/Sectors";
import Services from "./sections/Services";
import GlobalStyles from "./styles/GlobalStyles";
import { dark } from "./styles/Themes";
import Certificates from "./sections/Certificates.jsx";
function App() {
  // useLocoScroll();
  const topRef = useRef(null);
  const containerRef = useRef(null);
  const [Loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
  }, []);

  return (
    <>
      <GlobalStyles />

      <ThemeProvider theme={dark}>
        <LocomotiveScrollProvider
          options={{
            smooth: true,
            smartphone: {
              smooth: true,
            },
            tablet: {
              smooth: true,
            },
          }}
          watch={[]}
          containerRef={containerRef}
        >
          <AnimatePresence>{Loaded ? null : <Loader />}</AnimatePresence>
          <main className="App" data-scroll-container ref={containerRef}>
            <ScrollTriggerProxy />
            <AnimatePresence>
              <Navbar key="Navbar" />
              <Routes>
                <Route
                  path="/"
                  element={
                    <>
                      <Home key="home" />
                      <About key="about" />
                      <Services key="servives" />
                      <Sectors key="sectors" />
                      <ShopContainer key="Shop" />
                      <ProjectsContainer key="Projects" />
                      <Contactus key="contact us" />
                    </>
                  }
                />
                <Route
                  path="/about"
                  element={
                    <div className="overflow-hidden" ref={topRef}>
                      <AboutPage key="About Page" />
                    </div>
                  }
                />
                <Route
                  path="/projects-details"
                  element={
                    <div className="overflow-hidden" ref={topRef}>
                      <ProjectsDetails key="Projects details" />
                    </div>
                  }
                />

                <Route
                  path="/gallery"
                  element={
                    <div className="overflow-hidden" ref={topRef}>
                      <Gallery key="Gallery" />
                    </div>
                  }
                />
                <Route
                  path="/certificates"
                  element={
                    <div className="overflow-hidden" ref={topRef}>
                      <Certificates key="Certificates" />
                    </div>
                  }
                />
              </Routes>
            </AnimatePresence>
            <Footer key="Footer" />
          </main>
          <GoToTopBtn />
        </LocomotiveScrollProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
