import React from "react";
import styled from "styled-components";

import img1 from "../assets/Images/aboutCover.jpeg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
const langDir = localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr";
const lang = localStorage.getItem("i18nextLng");
const Section = styled.section`
  min-height: 100vh;
  margin: 0 auto;

  position: relative;
  @media (max-width: 48em) {
    width: 90vw;
  }

  @media (max-width: 30em) {
    width: 100vw;
  }
`;

const Left = styled.div`
  width: 30%;
  font-size: ${(props) => props.theme.fontlg};
  font-weight: 300;
  position: relative;
  z-index: 5;
  margin-top: 20%;

  @media (max-width: 64em) {
    width: 80%;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    margin: 0 auto;

    padding: 2rem;
    font-weight: 600;

    backdrop-filter: blur(2px);
    background-color: ${(props) => `rgba(${props.theme.textRgba},0.4)`};
    border-radius: 20px;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontmd};
  }
  @media (max-width: 30em) {
    font-size: ${(props) => props.theme.fontsm};
    padding: 2rem;
    width: 70%;
  }
`;

const Right = styled.div`
  width: 50%;
  position: relative;

  img {
    width: 100%;
    height: auto;
  }
  background-image: url(${img1});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 64em) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100vh;
      object-fit: cover;
    }
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontBig};
  font-family: ${lang === "ar" ? "29ltbukraregular" : ""};
  font-weight: 300;

  position: absolute;
  top: 1rem;
  ${lang === "ar" ? "right: 5%;" : "left: 5%;"}

  z-index: 5;

  span {
    display: inline-block;
  }

  @media (max-width: 64em) {
    font-size: ${(props) => `calc(${props.theme.fontBig} - 5vw)`};
    top: 0;
    left: 10%;
    color: white;
  }
  @media (max-width: 48em) {
    font-size: ${(props) => props.theme.fontxxxl};
  }
`;
const DarkOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(191, 25, 33, 0.6);
`;
const About = () => {
  const { t } = useTranslation();
  const tn = (key) => t(`navbar.${key}`);
  const ta = (key) => t(`about-us.${key}`);
  return (
    <Section id="fixed-target" className="about flex justify-between">
      <Title className="capitalize">{tn("about")}</Title>
      <Left
        className={`align-middle ${
          langDir === "rtl" ? "mr-20" : "ml-20"
        } flex flex-col items-center `}
      >
        <p className="text-center">{ta("title_one")}</p>
        <br />
        <br />
        <button
          onClick={() => (window.location.href = "/about")}
          className="text-[#BF1922] py-3 px-6 rounded-full border-[#BF1922] border-4 hover:bg-[#BF1922] hover:text-white transition-all duration-300 ease-in-out"
        >
          {t("more")}
        </button>
      </Left>
      <Right>
        <DarkOverlay></DarkOverlay>
      </Right>
    </Section>
  );
};

export default About;
