import { motion } from "framer-motion";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import img1 from "../assets/services/1-smoke-detector.png";
import img2 from "../assets/services/2-water-pump.png";
import img3 from "../assets/services/3-safety.png";
import img4 from "../assets/services/4-fire-extinguisher-1.png";
import img6 from "../assets/services/6-sprinkler.png";
import img7 from "../assets/services/7-fire.png";
import img8 from "../assets/services/8-fire-extinguisher.png";
import srcImg1 from "../assets/units/1.jpg";
import srcImg2 from "../assets/units/2.jpg";
import srcImg3 from "../assets/units/3.jpg";
import srcImg4 from "../assets/units/4.jpg";
import srcImg5 from "../assets/units/5.jpg";
import srcImg6 from "../assets/units/6.jpg";
import srcImg7 from "../assets/units/7.jpg";
const VideoContainer = styled(motion.section)`
  width: 100%;
  position: relative;
  height: 40vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.bgImage});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
`;

const langDir = localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr";
const Services = () => {
  const { t } = useTranslation();
  const ts = (key) => t(`services.${key}`);
  return (
    <div className="mt-6" id="services">
      <h1 className="text-center text-4xl my-6 flex items-center  justify-center">
        <span className="bg-[#E32832] text-white p-4 rounded-lg">
          {ts("title")}
        </span>
      </h1>
      <div className="border border-[#E32832]">
        <TwoServices
          img1={img1}
          img2={img2}
          bgImage={srcImg1}
          text1={ts("service1")}
          text2={ts("service2")}
        />
        <OneService
          img={img3}
          bgImage1={srcImg2}
          bgImage2={srcImg3}
          text={ts("service3")}
        />
        <TwoServices
          img1={img4}
          img2={img6}
          bgImage={srcImg4}
          text1={ts("service4")}
          text2={ts("service5")}
        />
        <OneService
          img={img6}
          bgImage1={srcImg5}
          bgImage2={srcImg6}
          text={ts("service6")}
        />
        <TwoServices
          img1={img7}
          img2={img8}
          bgImage={srcImg7}
          text1={ts("service7")}
          text2={ts("service8")}
        />
      </div>
    </div>
  );
};

export default Services;

const TwoServices = ({ img1, img2, text1, text2, bgImage }) => {
  return (
    <div className="flex flex-col lg:flex-row">
      <div className="flex flex-col gap-5 items-center justify-center w-full p-4 ">
        <img
          src={img1}
          alt={"service img"}
          style={{
            filter:
              "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
          }}
        />
        <p className="w-96 text-center">{text1}</p>
      </div>
      <VideoContainer
        initial={{ opacity: 0, scale: 1.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        bgImage={bgImage}
      >
        <div className="small-img-2" alt="cover img" />
      </VideoContainer>
      <div className="flex flex-col gap-5 items-center justify-center w-full p-4  ">
        <img
          src={img2}
          alt={"service img"}
          style={{
            filter:
              "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
          }}
        />
        <p className="w-96 text-center">{text2}</p>
      </div>
    </div>
  );
};

const OneService = ({ img, text, bgImage1, bgImage2 }) => {
  const isMobile = window.innerWidth < 768;
  return (
    <div className="flex flex-col lg:flex-row">
      <VideoContainer
        initial={{ opacity: 0, scale: 1.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        data-scroll
        data-scroll-speed={`${
          isMobile ? "0" : langDir === "rtl" ? "-0.3" : "0.3"
        }`}
        data-scroll-direction="horizontal"
        bgImage={bgImage1}
      >
        <div className="small-img-2" alt="cover img" />
      </VideoContainer>
      <div className="flex flex-col gap-5 items-center justify-center w-full p-4  ">
        <img
          src={img}
          alt={"service img"}
          style={{
            filter:
              "invert(23%) sepia(87%) saturate(2919%) hue-rotate(343deg) brightness(91%) contrast(96%)",
          }}
        />
        <p className="w-96 text-center">{text}</p>
      </div>
      <VideoContainer
        initial={{ opacity: 0, scale: 1.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 1 }}
        data-scroll
        data-scroll-speed={`${
          isMobile ? "0" : langDir === "rtl" ? "-0.3" : "0.3"
        }`}
        data-scroll-direction="horizontal"
        bgImage={bgImage2}
      >
        <div className="small-img-2" alt="cover img" />
      </VideoContainer>
    </div>
  );
};
