import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { baseURL, baseURLWithApi } from "../constants";

const ProjectsDetails = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const getProjects = async () => {
    setLoading(true);
    const res = await axios.get(`${baseURLWithApi}/projects`);
    setProjects(res.data);
    console.log(res.data);

    setLoading(false);
    return res.data;
  };
  useEffect(() => {
    getProjects();
  }, []);

  return (
    <div className="mt-32 container mx-auto mb-4" dir="ltr">
      <div className="flex flex-col w-full gap-4 items-center justify-center p-2">
        {loading && <h1>Loading...</h1>}
        {projects &&
          projects?.map((project) => (
            <div
              key={project._id}
              className="border-8 border-gray-700 w-full p-4 rounded-xl shadow-md hover:shadow-lg flex flex-col lg:flex-row items-center justify-between"
            >
              <div
                className={`flex gap-4 flex-col w-full  lg:w-1/2 items-start`}
              >
                <h2 className="text-xl font-bold text-red-600 ">
                  {project.title}
                </h2>
                <p className="text-gray-600 text-lg">{project.desc}</p>
              </div>
              <div className="w-full lg:w-[35%]">
                <img
                  src={`${baseURL}/files/${project.img.filename}`}
                  alt={project.title}
                  className="w-full h-72 object-contain rounded-lg"
                />
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ProjectsDetails;
