import React, { useEffect, useState } from "react";
import axios from "axios";

import { baseURLWithApi } from "../constants";
import Shop from "../sections/Shop";

const ShopContainer = () => {
  const [partners, setPartners] = useState([]);
  const [loading, setLoading] = useState(true);

  const getPartners = async () => {
    try {
      const res = await axios.get(`${baseURLWithApi}/partners`);
      setPartners(res.data.partners);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch partners", error);
    }
  };

  useEffect(() => {
    getPartners();
  }, []);

  return <>{loading ? <p>Loading...</p> : <Shop partners={partners} />}</>;
};

export default ShopContainer;
