import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import "react-dropdown/style.css";
import { useTranslation } from "react-i18next";
import { FiAlignJustify } from "react-icons/fi";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import { Link } from "react-router-dom";
import styled from "styled-components";
import logo from "../assets/Images/logo.png";
import MobileDrawer from "./MobileDrawer";

const NavContainer = styled(motion.div)`
  position: absolute;
  top: ${(props) => (props.click ? "0" : `-${props.theme.navHeight}`)};
  transition: all 0.1s ease;
  z-index: 6;
  width: 100vw;
  background-color: #bf1922;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999999999;

  @media (max-width: 40em) {
    top: ${(props) => (props.click ? "0" : `calc(-50vh - 4rem)`)};
  }
`;

const MenuItems = styled(motion.ul)`
  position: relative;
  height: ${(props) => props.theme.navHeight};
  background-color: #bf1922;
  color: #bf1922;
  display: flex;
  justify-content: space-around;
  align-items: center;
  list-style: none;

  width: 100%;
`;

const Item = styled(motion.li)`
  text-transform: uppercase;
  color: ${(props) => props.theme.text};
  &:hover {
    color: #22252a;
  }
  @media (max-width: 40em) {
    flex-direction: column;
    padding: 0.5rem 0;
  }
`;

const Navbar = () => {
  const [click, setClick] = useState(true);
  const { t, i18n } = useTranslation();
  const tn = (key) => t(`navbar.${key}`);
  const [isOpen, setIsOpen] = useState(false);
  const { scroll } = useLocomotiveScroll();

  const handleScroll = (id) => {
    let elem = document.querySelector(id);
    scroll.scrollTo(elem, {
      offset: "-100",
      duration: "2000",
      easing: [0.25, 0.0, 0.35, 1.0],
    });
  };
  const handleChange = () => {
    const currentLang = localStorage.getItem("i18nextLng");
    if (currentLang === "ar") {
      i18n.changeLanguage("en");
      localStorage.setItem("i18nextLng", "en");
      window.location.reload();
    } else {
      i18n.changeLanguage("ar");
      localStorage.setItem("i18nextLng", "ar");
      window.location.reload();
    }
  };
  useEffect(() => {
    if (localStorage.getItem("i18nextLng") === "ar") {
      i18n.changeLanguage("ar");
    } else {
      i18n.changeLanguage("en");
    }
  }, [localStorage.getItem("i18nextLng")]);
  const handleGoToProjects = () => {
    window.location.href = "/projects";
  };
  return (
    <NavContainer
      id="nav"
      click={+click}
      initial={{ y: `-100%` }}
      animate={{ y: 0 }}
      // transition={{ duration: 2, delay: 2 }}
    >
      <MenuItems>
        <div>
          <img src={logo} alt="professional address" className=" max-w-44" />
        </div>
        <div
          className="md:hidden lg:hidden block "
          onClick={() => setIsOpen(!isOpen)}
        >
          <FiAlignJustify color="#fff" size={32} />
        </div>
        <MobileDrawer isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="gap-10 lg:gap-30 hidden md:flex">
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#home")}
          >
            <div
              onClick={() => (window.location.href = "/")}
              className="cursor-pointer"
            >
              {tn("home")}
            </div>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll(".about")}
          >
            <Link to="/">{tn("about")}</Link>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#shop")}
          >
            <Link to="/">{tn("partners")}</Link>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#sectors")}
          >
            <Link to="/">{tn("sectors")}</Link>
          </Item>

          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#projects")}
          >
            <div className="cursor-pointer">{tn("projects")}</div>
          </Item>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#contact")}
          >
            {" "}
            <Link to="/">{tn("contact")}</Link>
          </Item>
        </div>
        <div>
          <Item
            whileHover={{ scale: 1.1, y: -5 }}
            whileTap={{ scale: 0.9, y: 0 }}
            onClick={() => handleScroll("#home")}
          >
            <button onClick={() => handleChange()}>
              {localStorage.getItem("i18nextLng") === "ar"
                ? "English"
                : "العربية"}
            </button>
          </Item>
        </div>
      </MenuItems>
    </NavContainer>
  );
};

export default Navbar;
