// import "@fontsource/sirin-stencil";
// import "@fontsource/kaushan-script";
// import "@fontsource-variable/roboto-slab";

import { createGlobalStyle } from "styled-components";
const langDir = localStorage.getItem("i18nextLng") === "ar" ? "rtl" : "ltr";
const GlobalStyles = createGlobalStyle`
${
  "" /* *{
    outline: 1px solid red !important;
}  */
}

html.has-scroll-smooth {
    overflow: hidden;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;  
}

*,*::before,*::after{
    margin: 0;
    padding: 0;
}

body{
    font-family: ${
      langDir === "rtl" ? "29ltbukraregular" : "Binaria-Regular"
    }, sans-serif, sans-serif;
    src: ${
      langDir === "rtl"
        ? 'url("../fonts/29ltbukraregular.otf")'
        : ' url("../fonts/Binaria-Regular.otf")'
    } format("opentype");
    font-weight: normal;
    overflow-x: hidden;
    color: #000;
    direction: ${langDir};
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
}
a{
    color: inherit;
    text-decoration:none;
}
`;

export default GlobalStyles;
