import React, { useEffect, useState } from "react";
import axios from "axios";

import { baseURLWithApi } from "../constants";
import Projects from "../sections/Projects";

const ProjectsContainer = () => {
  const [randomProjects, setRandomProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const getRandomProjects = async () => {
    try {
      const res = await axios.get(`${baseURLWithApi}/random-projects`);
      setRandomProjects(res.data.randomProjects);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch randomProjects", error);
    }
  };

  useEffect(() => {
    getRandomProjects();
  }, []);

  return (
    <>{loading ? <p>Loading...</p> : <Projects projects={randomProjects} />}</>
  );
};

export default ProjectsContainer;
