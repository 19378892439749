import React from "react";
import logo from "../assets/Images/fireLogo.png";
import { useTranslation } from "react-i18next";
const TextSection = () => {
  const { t } = useTranslation();
  const tt = (key) => t(`textSection.${key}`);
  return (
    <div className="container gap-7 mx-auto flex flex-col items-center justify-center mt-10 lg:mt-16 p-3 lg:p-0 ">
      <img src={logo} alt="about us" className="max-w-96" />
      <h2 className="text-xl lg:text-5xl font-bold text-[#BF1922]">
        {tt("title")}
      </h2>
      <p className="text-lg lg:text-3xl text-justify">{tt("subtitle")}</p>
    </div>
  );
};

export default TextSection;
