import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
// import { Toaster, toast } from "sonner";
const Contactus = () => {
  const { t } = useTranslation();
  const tc = (key) => t(`contact.${key}`);
  const isMobile = window.innerWidth < 768;
  const formRef = useRef();
  const handleSumbit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("service_g1q9zu9", "template_awcdgtk", formRef.current, {
        publicKey: "mJ4BLujh0NPujgFkV",
      })
      .then(
        () => {
          Swal.fire({
            icon: "success",
            title: t("success-message"),
            padding: "2em",
            customClass: "sweet-alerts",
          });
          // toast.success(t("success-message"));
          console.log("success");
          formRef.current.reset();
        },
        () => {
          console.log("error");
        }
      );
  };
  return (
    <section
      id="contact"
      className="bg-white flex flex-col lg:flex-row items-center justify-center gap-9"
    >
      {/* <Toaster /> */}

      <div className="mt-0">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d463877.31242950493!2d46.49288193599672!3d24.725455372447055!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh%20Saudi%20Arabia!5e0!3m2!1sen!2seg!4v1710297238348!5m2!1sen!2seg"
          width={`${isMobile ? "400" : "600"}`}
          height="450"
          allowFullScreen
          loading="lazy"
          title="Google Map"
          referrerPolicy="no-referrer-when-downgrade"
        />
      </div>
      <div className="py-8 lg:py-16 px-4 max-w-screen-2xl">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold font-serif text-center text-gray-900">
          {tc("title")}
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 d sm:text-xl">
          {tc("subtitle")}
        </p>
        <form
          onSubmit={(e) => handleSumbit(e)}
          className="space-y-8"
          ref={formRef}
        >
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              {tc("form.first-name")}
            </label>
            <input
              type="text"
              name="firstName"
              id="subject"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
              placeholder={tc("placeholder.first-name")}
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              {tc("form.last-name")}
            </label>
            <input
              type="text"
              name="lastName"
              id="subject"
              className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
              placeholder={tc("placeholder.last-name")}
              required
            />
          </div>
          <div>
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              {tc("form.email")}
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              placeholder={tc("placeholder.email")}
              required
            />
          </div>

          <div className="sm:col-span-2">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              {tc("form.message")}
            </label>
            <textarea
              name="message"
              id="message"
              rows="6"
              className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder={tc("placeholder.message")}
            ></textarea>
          </div>
          <button
            type="submit"
            className="py-3 px-5 text-sm font-medium text-center text-white rounded-lg bg-slate-800 sm:w-fit hover:bg-primary-800 focus:ring-4 focus:outline-none focus:ring-primary-300 "
          >
            {tc("form.submit")}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contactus;
