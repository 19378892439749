import axios from "axios";
import React, { useEffect, useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/plugins/captions.css";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { baseURL, baseURLWithApi } from "../constants";

const Certificates = () => {
  const [index, setIndex] = React.useState(-1);
  const [randomProjects, setRandomProjects] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadImageDimensions = (url) => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  useEffect(() => {
    const fetchProjectsWithDimensions = async () => {
      const res = await axios.get(`${baseURLWithApi}/certificates`);
      const projectsWithDimensions = await Promise.all(
        res.data.certificates.map(async (photo) => {
          const { width, height } = await loadImageDimensions(
            `${baseURL}/files/${photo.filename}`
          );

          return { ...photo, width, height };
        })
      );
      setRandomProjects(projectsWithDimensions);
      setLoading(false);
    };

    fetchProjectsWithDimensions();
  }, []);

  const slides = randomProjects.map((photo) => ({
    id: photo.filename,
    src: `${baseURL}/files/${photo.filename}`,
    width: photo.width,
    height: photo.height,
  }));

  return (
    <div className="mt-32" style={{ minHeight: "calc(100vh - 64px)" }}>
      <PhotoAlbum
        layout="columns"
        photos={slides}
        onClick={({ index: current }) => setIndex(current)}
      />
      <Lightbox
        index={index}
        plugins={[Zoom]}
        slides={slides}
        open={index >= 0}
        close={() => setIndex(-1)}
      />
    </div>
  );
};

export default Certificates;
