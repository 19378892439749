import React from "react";
import { useLocomotiveScroll } from "react-locomotive-scroll";
import styled from "styled-components";

import { useTranslation } from "react-i18next";
import { BiSolidPhoneCall } from "react-icons/bi";
import { FaLinkedin } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { SlBookOpen } from "react-icons/sl";
import { Link } from "react-router-dom";
import Logo from "../assets/Images/logo.png";
import companyProfile from "../assets/profile.pdf";

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;

  background-color: #22252a;
  color: ${(props) => props.theme.text};
`;

const Footer = () => {
  const { scroll } = useLocomotiveScroll();
  const { t, i18n } = useTranslation();

  const tn = (key) => t(`navbar.${key}`);
  const handleScroll = (id) => {
    let elem = document.querySelector(id);

    if (window.location.pathname === "/") {
      scroll.scrollTo(elem, {
        offset: -100,
        duration: 2000,
        easing: [0.25, 0.0, 0.35, 1.0],
      });
    } else {
      window.location.href = `/${id}`;
    }
  };

  return (
    <Section className="p-4 gap-5 h-[100vh] lg:h-auto">
      <div className="flex flex-col lg:flex-row  items-center justify-around w-full gap-[100px] lg:gap-0">
        <div className="flex flex-col items-center lg:items-start gap-4">
          <h2 className="text-xl lg:text-2xl">{t("links")}</h2>
          <ul className="flex gap-4 cursor-pointer">
            <li
              aria-hidden="true"
              onClick={() => handleScroll("#fixed-target")}
            >
              {tn("about")}
            </li>
            <li aria-hidden="true" onClick={() => handleScroll("#services")}>
              {tn("services")}
            </li>
            <li aria-hidden="true" onClick={() => handleScroll("#contact")}>
              {tn("contact")}
            </li>
          </ul>
          <div>
            <Link
              to={companyProfile}
              target="_blank"
              rel="noreferrer"
              className="text-white flex items-center gap-3"
            >
              <SlBookOpen size={24} />
              {t("companyProfile")}
            </Link>
          </div>
        </div>
        <img
          src={Logo}
          alt="logo"
          className={`max-w-[50%] lg:max-w-[14%]  ${
            i18n.language === "ar" ? "lg:-mr-[115px]" : "lg:-ml-[115px]"
          } m-0`}
        />

        <div className="flex gap-4 cursor-pointer">
          <a
            href={"https://wa.me/+966543200045"}
            target="_blank"
            rel="noreferrer"
          >
            <BiSolidPhoneCall size={32} />
          </a>
          <a
            href={"mailto:info@professional-address.com"}
            target="_blank"
            rel="noreferrer"
          >
            <MdOutlineEmail size={32} />
          </a>
          <a
            href={"https://www.linkedin.com/company/professional-address"}
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin size={32} />
          </a>
        </div>
      </div>
      <div className="flex items-center justify-center">
        <p>© 2024 All Rights Reserved</p>
      </div>
    </Section>
  );
};

export default Footer;
